<template>
    <div class="contacts">
        <img  src="../assets/img/address.jpg" alt=""><br>
        <p class="h2">Наши контакты:</p>
        <br>
        <br>
        <p>Адрес: г. Москва, ул.Автозаводская, 23а, корпус 2, оф.311</p>
        <p>Телефон: <a class="h3" href="tel:+79264080006">+7 (926) 408-00-06 </a></p>
        <div @click="backToHome" class="about__btn btn"><img src="../assets/img/back.png" alt="">Назад</div>
    </div>
</template>

<script>
  export default {
    name: "contacts",
    emits: [
      'backToHome'
    ],
    methods: {
      backToHome () {
        this.$emit('backToHome')
      }
    }
  }
</script>

<style lang="scss">
.contacts {
    .h2 {
        display: inline-block;
        margin: 0 auto;
    }
    h3 {
        display: inline-block;
        margin-left: auto;
        margin-right: 100px;
    }
    .about__btn {
        margin: 25px auto;
    }
}
</style>
