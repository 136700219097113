<template>
  <div class="notice">
    <div class="notice__wrap" v-if="!isInputCurrentCity">
      <div class="notice__title">{{title}}
        <p class="notice__subtitle">{{subtitle}}</p>
      </div>
      <div class="btn__wrap" v-if="isPopup === 11">
        <div @click="currentCity" class="notice__btn" style="background-color: red">
          Не верно
        </div>
        <div @click="removePopup" class="notice__btn">
          Верно
        </div>
      </div>
      <div class="btn__wrap" v-if="isPopup !== 11">
        <div @click="removePopup" class="notice__btn">
          Ok
        </div>
      </div>
    </div>
    <div class="notice__wrap" v-if="isInputCurrentCity">
      <div class="notice__title">Введите ваш город <br><br>
        <input v-on:keyup.enter="setClientCity" v-model="clientCity" type="text" class="notice__subtitle">
      </div>
      <div class="btn__wrap">
        <div @click="setClientCity" class="notice__btn">
          Ok
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notification',
  data () {
    return {
      isInputCurrentCity: false, // переключатель
      clientCity: ''
    }
  },
  props: {
    title: String,
    subtitle: String,
    isPopup: Number
  },
  emits: [
    'removePopup', 'setClientCity'
  ],
  methods: {
    removePopup () {
      this.$emit('removePopup') // говорим родителю что надо закрыть окно
    },
    currentCity () {
       this.isInputCurrentCity = true // показываем поле ввода города и скрываем другие кнопки
    },
    setClientCity () {
      this.$emit('setClientCity', this.clientCity) // передаем родителю новый город
    },
  }
}
</script>

<style lang="scss">
.notice {
  width: 320px;
  height: 100px;
  top: 50px;
  left: calc(50% - 160px);
  position: fixed;
  background: rgba(0,0,0, 0.7);
  box-shadow: 0 44px 68px rgba(0,0,0,0.5), 0 50px 70px rgba(0,0,0,0.5);
  .notice__wrap {
    background: white;
    position: relative;
    display: inline-block;
    padding: 20px;
    min-width: 320px;
    /*width: 70%;*/
    /*min-height: calc(60% + 100px);*/
    max-width: 500px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 5px;
    .notice__close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .notice__title {
      top: calc(10% + 50px);
      text-align: center;
      font-size: calc( (100vw - 320px)/(1920 - 320) * (20 - 5) + 14px);
      color: black;
      margin: 0 10px 30px 10px;
      font-weight: 600;
      p {
        font-size: calc( (100vw - 320px)/(1920 - 320) * (14 - 5) + 12px);;
        font-weight: initial;
      }
      input {
        width: 350px;
        padding: 5px;
        border-radius: 2px;
        margin: 10px;
        border: 1px dashed rgba(0, 255, 93, 0.5);
        &:focus {
          outline:none;
          border: initial;
          border-bottom: 2px dashed rgba(0, 255, 93, 0.5);
        }
      }
    }
    .btn__wrap {
      display: flex;
      justify-content: space-between;
      .notice__btn {
        width: 40%;
        margin: 0 auto;
        background-color: #0F8831;
        color: white;
        text-align: center;
        border-radius: 4px;
        padding: 5px;
        cursor: pointer;
      }
    }
  }
}
</style>
